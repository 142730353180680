<template>
  <!-- 订单列表 -->
    <div class="orderListWrapper">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        offset="50"
        @load="onLoad"
        v-show="orderListData.length > 0"
      >
        <van-cell v-for="(item, index) in orderListData" :key="index" class="cell_list" >
          <div>
            <div class="topTitle" @click="Look(item.orderNo)">
              <span>查看物流信息</span>
              <van-icon name="arrow" />
            </div>
            <ul class="contentUl">
              <li class="contentLi">
                <p class="title">商品名称：</p>
                <p class="content">{{ item.productName }}</p>
              </li>
              <li class="contentLi">
                <p class="title">订单编号：</p>
                <p class="content">{{ item.orderNo }}</p>
                <button class="tag-read" :data-clipboard-text="item.orderNo" @click="copy('.tag-read')">复制</button>
              </li>
              <li class="contentLi">
                <p class="title">快递公司：</p>
                <p class="content">{{ item.logisticsCompany }}</p>
              </li>
              <li class="contentLi">
                <p class="title">快递单号：</p>
                <p class="content">{{ item.logisticsNo }}</p>
                <button class="tag-reads" :data-clipboard-text="item.logisticsNo" @click="copy('.tag-reads')">复制</button>
              </li>
            </ul>
          </div>
          <!-- <div v-if="item.type === '2'">
            <div class="topTitle">花费充值</div>
            <ul class="contentUl">
              <li class="contentLi">
                <p class="title">充值金额：</p>
                <p class="content">{{ item.money }}</p>
              </li>
              <li class="contentLi">
                <p class="title">充值时间：</p>
                <p class="content">{{ item.payTimer }}</p>
              </li>
              <li class="contentLi">
                <p class="title">充值单号：</p>
                <p class="content">{{ item.payOrder }}</p>
                <button class="tag-reads" :data-clipboard-text="item.payOrder" @click="copy('.stag-reads')">复制</button>
              </li>
            </ul>
          </div>
          <div v-if="item.type === '3'">
            <div class="topTitle">投诉</div>
            <ul class="contentUl">
              <li class="contentLi">
                <p class="title">提交时间：</p>
                <p class="content">{{ item.timer }}</p>
              </li>
              <li class="contentLi">
                <p class="title">处理结果：</p>
                <p class="content">{{ item.success }}</p>
              </li>
            </ul>
          </div> -->
        </van-cell>
      </van-list>
      <van-list v-show="orderListData.length <= 0" class="noOrder">
        暂无订单信息
      </van-list>
    </div>
  </template>
  
  <script>
  import Clipboard from 'clipboard';
  import { getOrderList } from '../../../api/api'
  export default {
    name: 'orderList',
    props: {
      active: {
        type: String,
        default () {
          return '0'
        }
      },
      phoneNumber: {
        type: String,
        default () {
          return ''
        }
      }
    },
    data () {
      return {
        loading: false,
        finished: false,
        orderListData: [],
        page: 1,
        pageSize: 10,
        count: null
      }
    },
    watch: {
      active: {
        immediate: true,
        handler () {
          console.log(111)
        }
      }
    },
    methods: {
      refresh () {
        this.orderListData = []
        this.count = null
        this.page = 1
        this.finished = false
        this.queryOrderList()
      },
      queryOrderList () {
        this.loading = true
        let params = {
          mobile: this.phoneNumber,
          page: this.page,
          pageSize: this.pageSize
        }
        getOrderList(params).then(res => {
          if (res && res.code === 200) {
            this.count = res.result.total
            if (res.code === 200) {
              res.result.records.forEach(item => {
                this.orderListData.push(item)
              });
              this.loading = false
              this.$emit('ok')
            }
          } else {
            this.$emit('ok')
            this.loading = false
            this.finished = true
          }
        })
      },
      onLoad() {
        setTimeout(() => {
          if (this.orderListData.length >= this.count) {
            this.finished = true
          } else {
            this.finished = false
            this.page++
            this.queryOrderList()
          }
        }, 1000)
      },
      copy(argument) {
        var clipboard = new Clipboard(argument)
        clipboard.on('success', e => {
          console.log('复制成功')
          // 释放内存
          clipboard.destroy()
        })
        clipboard.on('error', e => {
          // 不支持复制
          console.log('该浏览器不支持自动复制')
          // 释放内存
          clipboard.destroy()
        })
      },
      Look (params) {
        window.location.href = `https://m.kuaidi100.com/result.jsp?nu=${params}`
      }
    }
  }
  </script>

<style scoped lang="less">
  .orderListWrapper {
    margin: 0 10px;
    padding-bottom: 30px;
    .cell_list {
      margin-bottom: 10px;
      .topTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        font-weight: bold;
        color: #333;
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 15px;
      }
      .contentUl {
        margin: 10px 0;
        .contentLi {
          display: flex;
          padding: 5px 0;
          align-items: center;
          font-size: 13px;
          font-weight: 500;
          .title {
            width: 80px;
            color: #333;
          }
          .content {
            flex: 1;
            color: #666;
            padding-left: 5px;
          }
        }
        .tag-read, .stag-read, .tag-reads {
          font-weight: 500;
          color: #3D94F6;
          font-size: 11px;
          border: 1px solid #3D94F6;
          border-radius: 2px;
          line-height: 14px;
          width: 40px;
          background-color: #fff;
        }
      }
    }
  }
</style>