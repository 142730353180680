<template>
  <div class="order">
    <van-nav-bar
      :title="this.$route.meta.title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <ul class="topSearch">
      <li class="phoneWrapper"><van-field v-model="phoneNumber" placeholder="请输入查询的手机号" type="number" maxlength="11" /></li>
      <li class="buttonWrapper"><van-button type="info" class="btn" @click="search" :loading="loading">查询</van-button></li>
      <li class="searchAlert">· 可查询快递进度、话费充值记录、投诉记录，请输入对应的手机号进行查询。</li>
    </ul>
    <div v-show="hidden">
      <p class="title">以下内容为查询结果</p>
      <!-- <van-tabs v-model="active" class="tabs">
        <van-tab title="我的订单" name="1"></van-tab>
        <van-tab title="投诉" name="2"> </van-tab>
      </van-tabs> -->
      <orderList :active="active" :phoneNumber="phoneNumber" ref="orderList" :ok="(loading = false)"></orderList>
    </div>
  </div>
</template>

<script>
import orderList from './modular/orderList.vue'

export default {
  name: 'order',
  data () {
    return {
      active: '1',
      phoneNumber: undefined,
      hidden: false,
      loading: false
    }
  },
  methods: {
    onClickLeft () {
      this.$router.replace({
        path: `/home2/${this.$store.state.userId}`
      })
    },
    search () {
      let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!phone.test(this.phoneNumber)) {
        this.$toast('请输入正确的手机号')
        return
      }
      this.hidden = true
      this.loading = true
      this.$refs.orderList.refresh()
    }
  },
  components: {
    orderList
  }
}
</script>

<style lang="less" scoped>
  .order {
    min-height: 100%;
    background-color: #f8f8f8;
    .topSearch {
      margin: 10px;
      background-color: #fff;
      .phoneWrapper, .buttonWrapper {
        // width: 100%;
        padding-top: 10px;
        margin: 20px 10px;
      }
      .btn {
        width: 100%;
      }
      .searchAlert {
        font-size: 13px;
        font-weight: 500;
        color: #666;
        text-align: left;
        padding: 0 10px 10px 10px;
      }
    }
    .title {
      font-size: 15px;
      font-weight: 500;
      color: #999;
      padding: 20px 0 30px 0;
    }
    .tabs {
      margin: 0 10px 20px 10px;
    }
  }
  /deep/ .van-field {
    background-color: #f7f7f7;
  }
</style>